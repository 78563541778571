
import { Component, Vue } from "vue-facing-decorator";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonLabel,
  IonMenu,
  IonContent,
  IonButton,
  IonAvatar,
  IonItem,
  IonImg,
} from "@ionic/vue";
import { ToastType } from "@interface/toast";
import UserClass from "@class/user/UserClass";

@Component({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonLabel,
    IonMenu,
    IonContent,
    IonButton,
    IonAvatar,
    IonItem,
    IonImg,
  },
})
export default class Navigation extends Vue {
  public get user(): UserClass {
    return this.$store.getters["auth/getUser"];
  }
  public redirect(item: string) {
    switch (item) {
      case "actuality":
        this.$router.push("actuality");
        break;
    }
  }
  public logout() {
    this.$refs.menu.$el.close().finally(() => {
      this.$toast.show(ToastType.success, this.$t("auth.login.logout-success"));
      this.$store.commit("auth/logout");
      this.$router.push("auth/login");
    });
  }
}
