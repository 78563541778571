import axios, { AxiosError } from "axios";
import { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { HttpTypeConfig } from "./config";
import { handleResponseError } from "./httpClient";

function getBearer(): string {
  const token = localStorage.getItem("token");
  if (token) {
    return "Bearer " + JSON.parse(token);
  }
  return "";
}

const httpPrivateClient = axios.create({
  headers: {
    "Content-Type": HttpTypeConfig.JSON_CONTENT_TYPE,
    Authorization: getBearer(),
  },
});

export default httpPrivateClient;

interface Headers {
  Authorization?: string;
}

// Add a request interceptor
httpPrivateClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const headers = config.headers as Headers;
    if (
      !headers ||
      !headers.Authorization ||
      headers.Authorization.length === 0
    ) {
      const bearer = getBearer();
      if (bearer && bearer.length > 0) {
        headers.Authorization = bearer;
        httpPrivateClient.defaults.headers.common["Authorization"] = bearer;
      }
    }

    return config;
  },
  function (error: Error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
httpPrivateClient.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  function (error: AxiosError) {
    return handleResponseError(httpPrivateClient, error);
  }
);
