import { AxiosRequestConfig, AxiosResponse } from "axios";
import httpPublicClient from "../httpPublicClient";
import { ServerApi, VersionApi } from "../httpClient";
import httpPrivateClient from "../httpPrivateClient";
import { HttpTypeConfig } from "../config";

export class HttpService {
  private static getHttpClientHandle(api: ServerApi = ServerApi.PUBLIC) {
    return api === ServerApi.PUBLIC ? httpPublicClient : httpPrivateClient;
  }

  public static get<T>(
    url: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig,
    version: string = VersionApi.V3,
    bufferLink = false
  ): Promise<AxiosResponse> {
    if (config) {
      config.signal = controller.signal;
    } else {
      config = {
        signal: controller.signal,
      };
    }

    if (bufferLink) {
      config.responseType = HttpTypeConfig.ARRAY_BUFFER;
      config.responseEncoding = HttpTypeConfig.BINARY_ENCODE;
      config.headers = {
        "Content-Type": HttpTypeConfig.PDF_CONTENT_TYPE,
      };
    }

    config.baseURL = version;

    console.log(config);

    return this.getHttpClientHandle(api).get<T>(url, config);
  }

  public static post<T>(
    url: string,
    data = {},
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig,
    version: string = VersionApi.V3
  ): Promise<AxiosResponse> {
    if (config) {
      config.signal = controller.signal;
    } else {
      config = {
        signal: controller.signal,
      };
    }

    config.baseURL = version;

    return this.getHttpClientHandle(api).post<T>(url, data, config);
  }

  public static put<T>(
    url: string,
    data = {},
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig,
    version: string = VersionApi.V3
  ): Promise<AxiosResponse> {
    if (config) {
      config.signal = controller.signal;
    } else {
      config = {
        signal: controller.signal,
      };
    }

    config.baseURL = version;

    return this.getHttpClientHandle(api).put<T>(url, data, config);
  }

  public static patch<T>(
    url: string,
    data = {},
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig,
    version: string = VersionApi.V3
  ): Promise<AxiosResponse> {
    if (config) {
      config.signal = controller.signal;
    } else {
      config = {
        signal: controller.signal,
      };
    }

    config.baseURL = version;
    config.headers = {
      "Content-type": "application/merge-patch+json",
    };

    return this.getHttpClientHandle(api).patch<T>(url, data, config);
  }

  public static delete<T>(
    url: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PUBLIC,
    config?: AxiosRequestConfig,
    bufferLink = false,
    version: string = VersionApi.V3
  ): Promise<AxiosResponse> {
    if (config) {
      config.signal = controller.signal;
    } else {
      config = {
        signal: controller.signal,
      };
    }

    if (bufferLink) {
      config.responseType = HttpTypeConfig.ARRAY_BUFFER;
      config.responseEncoding = HttpTypeConfig.BINARY_ENCODE;
      config.headers = {
        "Content-Type": HttpTypeConfig.PDF_CONTENT_TYPE,
      };
    }

    config.baseURL = version;

    return this.getHttpClientHandle(api).delete<T>(url, config);
  }
}
