import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
} from "axios";
import store from "@plugin/vuex";
import router from "@plugin/router";
import { HttpTypeConfig } from "./config";
import { environment } from "@/environements/environement";

axios.defaults.headers.common["Content-Type"] =
  HttpTypeConfig.JSON_CONTENT_TYPE;

export const enum ServerApi {
  PUBLIC = "public",
  PRIVATE = "private",
}

export const VersionApi = {
  V3: environment.domainAPIV3,
  V4: environment.domainAPIV4,
};

export interface JwtToken {
  refresh_token: string;
  token: string;
}

export interface ApiOptions {
  controller?: AbortController;
  api?: ServerApi;
  config?: AxiosRequestConfig | undefined;
}

export interface ApiGetOptions {
  bufferLink?: boolean;
}

export function jwtHeader() {
  const token = localStorage.getItem("token");
  if (token) {
    return {
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("token") || ""),
    };
  }
  return {};
}

export function handleResponseError(
  instance: AxiosInstance,
  error: AxiosError
): Promise<AxiosResponse<unknown, unknown> | undefined> {
  // todo for all errors 404 - s
  if (
    error.response?.status !== 401 &&
    error.response?.status !== 400 &&
    error.response?.status !== 422
  ) {
    return Promise.reject(error);
  } else if (error.response?.status === 400 || error.response?.status === 422) {
    return Promise.reject(error);
  } else {
    return handleAuthorization(instance, error, false);
  }
}

export function handleAuthorization(
  instance: AxiosInstance,
  error: AxiosError,
  reload: boolean
): Promise<AxiosResponse<unknown> | undefined> {
  const refresh_token: string | null = localStorage.getItem("refresh_token");
  if (refresh_token) {
    return axios
      .post<JwtToken>(VersionApi.V3 + "/jwt-token/refresh", {
        refresh_token: JSON.parse(refresh_token),
      })
      .then((response: { data: { token: string } }) => {
        store.commit("auth/login", response.data);

        instance.defaults.headers.Authorization =
          "Bearer " + response.data.token;
        instance.defaults.headers.common["Authorization"] =
          "Bearer " + response.data.token;

        const config = error.config as AxiosRequestConfig;

        if (config.headers) {
          config.headers.Authorization = "Bearer " + response.data.token;
        }

        return axios
          .request(error.config as AxiosRequestConfig)
          .then((response: AxiosResponse<unknown>) => {
            return Promise.resolve(response);
          })
          .catch((error: AxiosError) => {
            console.warn(error);
            return Promise.reject(error);
          });
      })
      .catch((error: AxiosError) => {
        console.log(error, "ici <==")
        if (error.response?.status === 401 && !reload) {
          reload = true;

          store.commit("auth/logout");
          router.replace("/login");
        } else {
          console.warn("Unresolved error => :", error);
        }

        return Promise.reject(error);
      });
  }

  return Promise.reject(error);
}
