export default [
  {
    path: "/actuality",
    name: "ActualityHome",
    component: () => import("@page/actuality/ActualityList.vue"),
    children: [
      {
        path: ":id",
        name: "ActualityDetails",
        component: () => import("@page/actuality/ActualityList.vue"),
      },
    ],
  },
];
