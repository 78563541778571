import { AxiosRequestConfig } from "axios";
import { ServerApi, VersionApi } from "@api/httpClient";
import { HttpService } from "@api/services/http-service";
import { Session } from "@model/Session";
import { Participant } from "@/helpers/models";
import { ITEM_PER_PAGE_PARTICIPANTS } from "@constant/api";
import ClientClass from "@class/client/ClientClass";
export class SessionService {
  public static getSessions(
    status: string,
    coachId: string,
    itemsPerPage: number,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig
  ): Promise<{ "hydra:member": Session[] }> {
    return HttpService.get<Promise<{ "hydra:member": Session[] }>>(
      `/clubs/bookings?${status}&coaches.id=${coachId}&itemsPerPage=${itemsPerPage}`,
      controller,
      api,
      config
    ).then((resp) => resp.data as { "hydra:member": Session[] });
  }

  public static getParticipants(
    bookingId: string,
    itemsPerPage = ITEM_PER_PAGE_PARTICIPANTS,
    inQueue = false,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig
  ): Promise<{ "hydra:member": Participant[] }> {
    return HttpService.get<Promise<{ "hydra:member": Participant[] }>>(
      `/clubs/bookings/participants?itemsPerPage=${itemsPerPage}&order[createdAt]=asc&booking.id=${bookingId}&inQueue=${inQueue}`,
      controller,
      api,
      config
    ).then((resp) => resp.data as { "hydra:member": Participant[] });
  }

  public static searchClients(
    search: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig
  ): Promise<ClientClass[]> {
    return HttpService.get<Promise<ClientClass[]>>(
      `/clubs/clients.json?itemsPerPage=10&page=1&search=${search}&club.id=${process.env.VUE_APP_CLUB_ID}`,
      controller,
      api,
      config
    ).then((resp) => resp.data as ClientClass[]);
  }

  public static addParticipant(
    booking: string,
    client: string,
    inQueue: boolean,
    subscriptionCard: string,
    bookingOwner: boolean,
    paymentMethod: string,
    controller = new AbortController(),
    api: ServerApi = ServerApi.PRIVATE,
    config?: AxiosRequestConfig,
    version: string = VersionApi.V4
  ): Promise<Participant> {
    return HttpService.post<Promise<Participant>>(
      `/clubs/bookings/participants`,
      {
        booking,
        client,
        inQueue,
        subscriptionCard,
        bookingOwner,
        paymentMethod,
      },
      controller,
      api,
      config,
      version
    ).then((resp) => resp.data as Participant);
  }
}
