
import { Component, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import { InputTextType } from "@interface/index";
import { IonInput } from "@ionic/vue";

@Component({
  components: {
    IonInput,
  },
  emits: ["input", "focus", "blur", "update:modelValue"],
})
export default class InputText extends Vue {
  @Prop({ type: String, required: true }) name!: string;
  @Prop({ type: String }) value!: string;
  @Prop({ type: String, default: "text" }) type!: InputTextType;
  @Prop({ type: String }) placeholder!: string;
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) labelPlacement!: string;
  @Prop({ type: String }) clearInput!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Number }) maxlength!: number;
  @Prop({ type: Number }) minlength!: number;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) error!: boolean;
  @Model({
    type: String,
    default: "",
  })
  model!: string;
  @Watch("error")
  errorChanged(value: boolean) {
    if (value) {
      this.$refs["input-text"].$el.classList.add("ion-invalid");
    } else {
      this.$refs["input-text"].$el.classList.remove("ion-invalid");
    }
  }
}
