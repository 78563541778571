import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_input, {
    name: _ctx.name,
    value: _ctx.value,
    label: _ctx.label,
    "label-Placement": _ctx.labelPlacement,
    "clear-input": _ctx.clearInput,
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    ref: "input-text",
    type: _ctx.type,
    placeholder: _ctx.placeholder,
    required: _ctx.required,
    disabled: _ctx.disabled,
    maxlength: _ctx.maxlength,
    minlength: _ctx.minlength,
    readonly: _ctx.readonly,
    onIonFocus: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('focus', $event))),
    onIonBlur: _cache[2] || (_cache[2] = ($event: any) => (this.$emit('blur', $event))),
    onInput: _cache[3] || (_cache[3] = ($event: any) => (this.$emit('input', $event)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["name", "value", "label", "label-Placement", "clear-input", "modelValue", "type", "placeholder", "required", "disabled", "maxlength", "minlength", "readonly"]))
}